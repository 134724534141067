import { json, type LinksFunction, type LoaderFunctionArgs } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';

import { ClerkApp } from '@clerk/remix';
import { rootAuthLoader } from '@clerk/remix/ssr.server';
import * as Sentry from '@sentry/remix';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';

import { GenericErrorBoundary } from '~/components/misc/GenericErrorBoundary';
import { env, getPublicEnvs, type PublicEnvs } from '~/constants/env.server';
import stylesheet from '~/tailwind.css?url';
import { csrf } from '~/utils/csrf.server';
import { useNonce } from '~/utils/hooks/useNonce';
import { useOnClientOnly } from '~/utils/hooks/useOnClientOnly';
import { combineHeaders } from '~/utils/misc.server';


// Configure dayjs defaults
dayjs.extend(utc);
dayjs.extend(timezone);


export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'apple-touch-icon', type: 'image/png', href: '/apple-touch-icon.png?v=1' },
  { rel: 'icon', type: 'image/png', href: '/favicon-light.png?v=1', media: '(prefers-color-scheme: light)' },
  { rel: 'icon', type: 'image/png', href: '/favicon-dark.png?v=1', media: '(prefers-color-scheme: dark)'},
  { rel: 'stylesheet', href: 'https://rsms.me/inter/inter.css' },
  { rel: 'preconnect', href: 'https://fonts.cdnfonts.com', crossOrigin: 'anonymous' },
  { rel: 'stylesheet', href: 'https://fonts.cdnfonts.com/css/montserrat' },
];

/** Required loader for Clerk wrapper to work. */
export const loader = async (args: LoaderFunctionArgs) => {
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return rootAuthLoader(args, ({ request }) => {
    const userId = request.auth?.sessionClaims?.externalId;
    const userEmail = request.auth?.sessionClaims?.email;

    return json({
      ENV: getPublicEnvs(),
      csrfToken,
      userId: typeof userId === 'string' ? userId : undefined,
      userEmail: typeof userEmail === 'string' ? userEmail : undefined,
    } as const, {
      headers: combineHeaders(csrfCookieHeader ? { 'Set-Cookie': csrfCookieHeader } : null),
    });
  }, {
    jwtKey: env().CLERK_JWT_KEY,
  });
};

const Document = ({ children, jsonEnvs, nonce }: {
  children: React.ReactNode;
  jsonEnvs?: PublicEnvs;
  nonce: string;
}) => {
  return (
    <html lang="en" className="text-dark">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen w-full">
        {children}
        <ScrollRestoration nonce={nonce} />

        {/* Inject public ENVs */}
        {!!jsonEnvs && (
          <script
            suppressHydrationWarning
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(jsonEnvs)}`,
            }}
          />
        )}
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
};

export const ErrorBoundary = () => {
  const nonce = useNonce();

  return (
    <Document nonce={nonce}>
      <GenericErrorBoundary
        className='mt-40'
        statusHandlers={{
          404: ({ error }) => ({
            code: 404,
            title: "We couldn't find the page you were looking for",
            message: 'Try going back to the previous page.',
          }),
        }}
      />
    </Document>
  );
};

function App() {
  const data = useLoaderData<typeof loader>();

  const nonce = useNonce();

  // Set Sentry user client-side
  //
  useOnClientOnly(() => {
    if (data.userId) {
      Sentry.setUser({
        id: data.userId,
        email: data.userId,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [data.userId]);

  return (
    <Document jsonEnvs={data.ENV} nonce={typeof window === 'undefined' ? nonce : ''}>
      <AuthenticityTokenProvider token={data.csrfToken}>
        <Outlet />
      </AuthenticityTokenProvider>
    </Document>
  );
}

export default Sentry.withSentry(ClerkApp(App, {
  afterSignOutUrl: '/login',
  signInUrl: '/login',
  signUpUrl: '/signup',
  signInFallbackRedirectUrl: '/',
  signUpFallbackRedirectUrl: '/',
  telemetry: {
    disabled: true,
  },
}));
